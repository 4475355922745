<!------ Include the above in your HEAD tag ---------->
<br>
<div class="wrapper fadeInDown">
  <div id="formContent">

    <!-- Icon -->
    <div class="fadeIn first">
      <img src="https://thumbs.dreamstime.com/b/inicio-de-sesi%C3%B3n-contrase%C3%B1a-icono-c%C3%B3digo-seguridad-hermoso-dise%C3%B1o-y-acceso-completo-editable-para-proyectos-comerciales-medios-166831987.jpg" id="icon" alt="User Icon" />
    </div>

    <form [formGroup]="tokenForm"  (ngSubmit)="validateSession($event)">
      <div class="row">
        <div class="col">
          <input formControlName="userToken" type="text" class="form-control" placeholder="XXXXXX">
        </div>
      </div>
      <br>
      <input type="submit" class="fadeIn fourth" value="Validar">   
    </form>

  </div>
</div>